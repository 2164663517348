.text-mavi {
  color: #61dafb;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-sec {
  background-color: #282c34;
}

.kutu-sec {
  background-color: #282c34;
  padding: 10px;
  border-radius: 4px;
}

.br {
  border-radius: 4px;
}

.kutu {
  background-color: #222;
  padding: 10px;
  border-radius: 4px;
}

.all {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  position: relative;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.os {
  overflow: scroll;
  overflow-x: hidden;
}

.sidebar {
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 350px;
  z-index: 100000;
  padding: 20px;
}

.button-mavi {
  color: #222;
  background-color: #61dafb;
}
.button-mavi:hover {
  background-color: rgba(97, 218, 251, 0.8);
}

.button-yesil {
  color: #222;
  background-color: #62F068;
}
.button-yesil:hover {
  background-color: rgba(98, 240, 104, 0.8);
}

.button-red {
  color: #222;
  background-color: #F14C4C;
}
.button-red:hover {
  background-color: rgba(241, 76, 76, 0.8);
}

.button {
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 0;
  transition: 0.2s;
  cursor: pointer;
}

.fw-500 {
  font-weight: 500;
}

.theme-select {
  background-color: rgba(34, 34, 34, 0.3);
  padding: 10px;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 100;
  display: flex;
}
.theme-select .active {
  background-color: #61dafb;
  border-color: #61dafb !important;
}
.theme-select .selector {
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 2px;
  margin-right: 5px;
  width: 50px;
  height: 50px;
}
.theme-select .selector img {
  width: 100%;
  height: 100%;
}

.pro-sidebar > .pro-sidebar-inner {
  background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-left: 0 !important;
}

.pro-sidebar .pro-menu {
  padding-top: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-top: 0 !important;
  padding-left: 3px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  color: white;
  font-size: 19px;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-inner-item {
  flex-direction: row-reverse;
}

.pro-arrow-wrapper {
  position: static !important;
  transform: translate(0%) !important;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  border-color: #61dafb !important;
  border-width: 0 4px 4px 0 !important;
  padding: 4.5px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0 !important;
  padding-left: 1rem !important;
}

.pro-item-content:hover {
  color: #61dafb !important;
}

.sd-mod-1 {
  display: grid;
  grid-template-rows: 1fr 200px;
}

.search {
  border-radius: 4px;
  background-color: #4a4a4a;
  display: flex;
  align-items: center;
  padding: 5px;
}
.search:hover {
  background-color: #666666;
}
.search i {
  color: white;
}
.search input {
  color: white;
  margin-left: 4px;
  background-color: transparent;
  outline: none;
  border: none;
}
.search input::placeholder {
  color: white;
}

.back {
  border-radius: 1000px;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 2rem;
  margin-right: 1rem;
}
.back:hover {
  background-color: #282c34;
}

@keyframes gir {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
}
.gir {
  animation: 0.2s gir;
}

.infoBox {
  display: flex !important;
  width: unset !important;
}

.sk-big {
  height: 25px !important;
}

.sk-full {
  height: 80px;
}

.sk {
  width: 100%;
  height: 10px;
  margin: 5px 0;
  border-radius: 4px;
  background: #555;
  animation: loading 1s infinite linear alternate;
}

@keyframes loading {
  from {
    background-color: #555;
  }
  to {
    background-color: #999;
  }
}
.hover-fade {
  transition: 0.2s;
}
.hover-fade:hover {
  opacity: 0.8;
}

.btr {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tr {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bg-mavi {
  background-color: #61dafb;
}

.image-select {
  z-index: 10000;
  width: calc(90% - 450px);
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-opa {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: calc(100% - 350px);
  position: absolute;
  left: 350px;
}

.bad {
  background-color: #f73535;
  border-radius: 1000px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cam-sayfa {
  min-height: 100vh;
  background-color: #222;
}
.cam-sayfa .camera {
  padding: 1rem;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 2rem;
}
.cam-sayfa video {
  width: 100%;
  display: block;
  margin: auto;
}

.simg {
  width: 60px;
  height: 60px;
}

.img-slider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px;
  grid-gap: 1rem;
  border-radius: 4px;
}
.img-slider img {
  height: 100%;
  margin-right: 1rem;
}

.next-btn {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0px 3px 8px 3px #949494;
}

.emr-input {
  border-radius: 4px;
  background-color: #4a4a4a;
  padding: 5px;
  outline: none;
  border: 1px solid transparent;
}
.emr-input:hover {
  background-color: #666666;
}
.emr-input:focus {
  border-color: #61dafb;
}

.fg input {
  width: 100%;
  display: block;
  color: white;
}
.fg label {
  color: #61dafb;
}

.down {
  overflow: hidden;
  transition: 0.2s;
  color: white;
  font-weight: 500;
  max-height: 30000px;
  font-size: 17px;
}
.down:hover {
  cursor: pointer;
  color: #61dafb;
}

.tr-2 {
  transition: 0.2s;
}

.down-off {
  transition: 0.2s;
  max-height: 0 !important;
}

.fs {
  padding-left: 1rem;
}

.sc {
  padding-left: 1.5rem;
}

.ilk {
  font-size: 17px;
  font-weight: 500;
  color: white;
}
.ilk:hover {
  cursor: pointer;
  color: #61dafb;
}

.tss {
  color: white;
  -webkit-text-fill-color: #e84118;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.3px;
  font-size: 20px;
  -webkit-text-stroke-color: white;
}

.links {
  margin: 0;
  margin-top: 1rem;
  padding: 0;
}
.links li {
  list-style: none;
}
.links a {
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 10px 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}
.links a:hover {
  color: white;
}
.links a:active {
  color: #61dafb;
}
.links a i {
  margin-right: 0;
  color: #61dafb;
}

.no-mus {
  color: rgba(255, 255, 255, 0.75);
}
.no-mus:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75) !important;
}

.tab-con {
  display: flex;
}
.tab-con .active {
  background-color: #222 !important;
  color: #61dafb !important;
}
.tab-con .tab {
  font-weight: 500;
  cursor: pointer;
  background-color: #282c34;
  flex: 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  font-size: 18px;
}
.tab-con .tab:first-child {
  border-top-left-radius: 4px;
}
.tab-con .tab:last-child {
  border-top-right-radius: 4px;
}
.tab-con .tab i {
  margin-right: 3px;
}

.border {
  border: none !important;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 9in;
  width: 50%;
  display: block;
  margin: 0 auto;
}

.ziyaret {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  padding: 10px 0;
  border-radius: 4px;
}
.ziyaret:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}
.ziyaret .ziyaretci {
  text-align: right;
  opacity: 0.75;
}

.kapali {
  transition: 0.2s;
  max-height: 0;
  overflow: hidden;
  padding: 0 !important;
}

.detail {
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.1);
}

.ziyaret-active {
  background-color: rgba(255, 255, 255, 0.1);
}

