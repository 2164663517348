$primary: #61dafb;
$dark:#222;
$sec: #282c34;
$radius:4px;
$pd: #34bee4;
$yesil: #62F068;
$red: #F14C4C;

.text-mavi {
    color: $primary;
}

.cursor-pointer{
    cursor: pointer;
}
.bg-sec{
    background-color: $sec;
}
.kutu-sec{
    background-color: $sec;
    padding: 10px;
    border-radius: $radius;
}
.br{
    border-radius: $radius;
}

.kutu{
    background-color: $dark;
    padding: 10px;
    border-radius: $radius;
}

.all{
    width: 100vw;
    min-height: 100vh;
    height: 100vh;
    position: relative;
}
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
.os{
    overflow: scroll;
    overflow-x: hidden;


}

.sidebar{
    background-color: rgba($color: #000000, $alpha: 0.1);
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;

    width: 350px;
    z-index: 100000;
    padding: 20px;
}

.button-mavi{
    color: $dark;
    background-color: $primary;

    &:hover{
        background-color: rgba($color: $primary, $alpha: 0.8);

    }
}

.button-yesil{
    color: $dark;
    background-color: $yesil;
    
    &:hover{
        background-color: rgba($color: $yesil, $alpha: 0.8);
    }
}

.button-red{
    color: $dark;
    background-color: $red;

    &:hover{
        background-color: rgba($color: $red, $alpha: 0.8);
    }
}

.button{
    border: none;
    outline: none;
    border-radius: $radius;
    padding: 5px 0;
    transition: .2s;
    cursor: pointer;

}
.fw-500{
    font-weight: 500;
}
.theme-select{
    background-color: rgba($color: $dark, $alpha: 0.3);
    padding: 10px;
    border-radius: $radius;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 100;
    display: flex;
    .active{
        background-color: $primary;
        border-color: $primary !important;
    }
    .selector {
        cursor: pointer;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 2px;
        
        margin-right: 5px;
        img{
            width: 100%;
            height: 100%;
        }
        width: 50px;
        height: 50px;
    }

}

.pro-sidebar > .pro-sidebar-inner{
    background: transparent !important;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    padding-left: 0 !important;
}

.pro-sidebar .pro-menu{
    padding-top: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    padding-top: 0 !important;
    padding-left: 3px !important;

}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    color:white;
    font-size: 19px;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    background-color: transparent !important;
}

.pro-inner-item{
    flex-direction: row-reverse;
}

.pro-arrow-wrapper{
    position: static !important;
    transform: translate(0%) !important; 
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow{
    border-color: $primary !important;
    border-width: 0 4px 4px 0 !important;
    padding: 4.5px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
    background-color: transparent !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul{
    padding-top: 0 !important;
    padding-left: 1rem !important;
}

.pro-item-content:hover{
    color: $primary !important;
}

.sd-mod-1{
    display: grid;
    grid-template-rows:1fr 200px;
}

.search{
    border-radius: $radius;
    background-color: rgba($color: #4A4A4A, $alpha: 1);
    display: flex;
    align-items: center;
    padding: 5px;
    &:hover{
        background-color: #666666;
    }
    
    i{
        color: white;
    }
    input{
        
        &::placeholder{
            color: white;
        }            color: white;
        margin-left: 4px;
        background-color: transparent;
        outline: none;
        border: none;
    }
}
.back{
    border-radius: 1000px;
    padding: 10px;
    cursor: pointer;
    &:hover{
        background-color: $sec;
    }
    color: white;
    font-size: 2rem;
    margin-right: 1rem;
}
@keyframes gir{
    from{
        opacity: 0;
        transform: scale(0.9);
       
    }
}
.gir{
    animation: .2s gir;
}

.infoBox{
    display: flex !important;
    width: unset !important;
}
.sk-big{
    height: 25px !important;
}

.sk-full{
    height: 80px;
}
.sk{
    width: 100%;
    height: 10px;
    margin: 5px 0;
    border-radius: $radius;
    background: #555;
animation: loading 1s infinite linear alternate;

}

@keyframes loading {  
    from{
        background-color: #555;
    }
    to{
        background-color: #999;
    }
  }

.hover-fade{
    
    transition: .2s;
    &:hover{
        opacity: 0.8;
    }
}
.btr{
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
}
.tr{
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
}
.bg-mavi{
        background-color: $primary;
}

.image-select{
    z-index: 10000;
    width: calc(90% - 450px);
    left: 60%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.bg-opa{
    z-index: 100;
    background-color: rgba($color: #000000, $alpha: 0.1);
    height: 100vh;
    width: calc(100% - 350px);
    position: absolute;
    left: 350px;
}


.bad{
    background-color: rgb(247, 53, 53);
    border-radius: 1000px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cam-sayfa{
    min-height: 100vh;
    background-color: $dark;
.camera{
    padding: 1rem;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 2rem;
}
    video{
        
        width: 100%;
        display: block;
        margin: auto;
    }
}

.simg{
    width: 60px;
    height: 60px;
}

.img-slider{
    img{
        height: 100%;
        margin-right: 1rem;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px;
    grid-gap: 1rem;
    border-radius: $radius;
}

.next-btn{
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0px 3px 8px 3px #949494;
}

.emr-input{
    border-radius: $radius;
    background-color: rgba($color: #4A4A4A, $alpha: 1);
    padding: 5px;
    outline: none;
    border: 1px solid transparent;
    &:hover{
        background-color: #666666;
    }

    &:focus{
        border-color: $primary;
    }
}

.fg {


    input{
        width: 100%;
        display: block;
        color: white;
    }
    label{
color: $primary;
    }
}


.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    
}

.down{
    overflow: hidden;
    transition: .2s;
    color: white;
font-weight: 500;
max-height: 30000px;
&:hover{
    cursor: pointer;
    color: $primary;
}
    font-size: 17px;
}
.tr-2{
    transition: .2s;
}
.down-off{
    transition: .2s;

    max-height: 0 !important;
}



.fs{
    padding-left: 1rem;
}

.sc{
    padding-left: 1.5rem;
}

.ilk{
    font-size: 17px;
    font-weight: 500;
    color: white;
    &:hover{
        cursor: pointer;
        color: $primary;
    }
}

.tss{
    color: white;
    -webkit-text-fill-color: #e84118; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0.3px;
    font-size: 20px;
    -webkit-text-stroke-color: white;
}


.links{
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    li{
        list-style: none;
    }
    a{
        text-decoration: none;
        font-size: 18px;
        display: block;
        padding: 10px 20px;
        font-weight: 500;
        color: rgba(255,255,255,.75);
        &:hover{
            color: white;
        }
        &:active{
            color: $primary;
        }
        i{
            margin-right: 0;
            color: $primary;
        }
    }
}

.no-mus{
    color:rgba($color: #fff, $alpha: 0.75);
    &:hover{
cursor: pointer;
color:rgba($color: #fff, $alpha: 0.75) !important;

    }
}

.tab-con{
    display: flex;

    .active{
        background-color: $dark !important;
        color: $primary !important;
    }
    .tab{
font-weight: 500;
        &:first-child{
            border-top-left-radius: $radius;
        }

        &:last-child{
            border-top-right-radius: $radius;
        }
        &:hover{
            }
        cursor: pointer;
        background-color: $sec;
        flex: 1;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px;
        
        font-size: 18px;
        i{
            margin-right: 3px;
        }
    }
}
.border{
    border:none !important;
    height: 1px ;
    background-color: rgba(#fff,0.7);
    border-radius: 9in;
    width: 50%;
    display: block;
    margin: 0 auto;
}
.ziyaret{
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: white;
    padding: 10px 0;
    border-radius: $radius;
&:hover{
    cursor:pointer;
    background-color: rgba(#fff,0.1);
}
    .ziyaretci{
        text-align: right;
        opacity: 0.75;
        
    }
}

.kapali{
    transition: .2s;
    max-height: 0;
    overflow: hidden;
    padding: 0  !important;
}

.detail{
    padding: 2px;
    background-color: rgba(#fff,0.1);

}
.ziyaret-active{
    background-color: rgba(#fff,0.1);
}